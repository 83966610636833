<template>
  <div class="product-container about_jqr">
    <div class="jqr-cover-bg-div" v-if="state.showWebSite == true">
      <img
        src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaituozhe/jqr/fa-jqr-bg2.jpg"
        class="jqr-cover-bg-img"
      />

      <div class="absolute-product-img-div">
        <div class="flex-center-div">
          <img
            src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaituozhe/jqr/fa-jqr-1.png"
            v-if="state.selFaType == '01'"
            class="fa-jqr-pro-img animate fadeIn"
          />
          <img
            src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaituozhe/jqr/fa-jqr-2.png"
            v-else
            class="fa-jqr-pro-img"
          />
        </div>

        <div class="flex-radio-sel-div">
          <div
            class="radio-sel-child-div margin-right-24"
            @click="state.selFaType = '01'"
          >
            <div class="radio-sel-child-color-div"></div>
          </div>
          <div class="radio-sel-child-div" @click="state.selFaType = '02'">
            <div class="radio-sel-child-color-div bg-02"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="jqr-cover-bg-fs-div" v-if="state.showWebSite == true">
      <img
        src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaituozhe/jqr/fs-jqr-bg2.jpg"
        class="jqr-cover-bg-img"
      />

      <div class="fs-absolute-product-img-div">
        <div class="flex-center-div">
          <img
            src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaituozhe/jqr/fs-jqr-1.png"
            v-if="state.selFsType == '01'"
            class="fs-jqr-pro-img"
          />
          <img
            src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaituozhe/jqr/fs-jqr-2.png"
            v-if="state.selFsType == '02'"
            class="fs-jqr-pro-img"
          />

          <img
            src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaituozhe/jqr/fs-jqr-3.png"
            v-if="state.selFsType == '03'"
            class="fs-jqr-pro-img"
          />
        </div>

        <div class="flex-radio-sel-div">
          <div
            class="radio-sel-child-div margin-right-24"
            @click="state.selFsType = '01'"
          >
            <div class="radio-sel-child-color-div"></div>
          </div>
          <div
            class="radio-sel-child-div margin-right-24"
            @click="state.selFsType = '02'"
          >
            <div class="radio-sel-child-color-div bg-02"></div>
          </div>

          <div class="radio-sel-child-div" @click="state.selFsType = '03'">
            <div class="radio-sel-child-color-div bg-03"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="flexslider flexslider1" v-if="state.showWebSite == true">
      <ul class="slides">
        <li>
          <dl>
            <img
              class="k1-2-img"
              src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaituozhe/jqr/ma-jqr-bg2-1.jpg"
            />
          </dl>
        </li>
        <li>
          <dl>
            <img
              class="k1-2-img"
              src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaituozhe/jqr/ma-jqr-bg2-2.jpg"
            />
          </dl>
        </li>
      </ul>
    </div>

    <div
      class="mobile-jqr-conver-bg-div margin-top-50"
      v-if="state.showWebSite == false"
    >
      <img
        src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaituozhe/jqr/mobile-fa-jqr-bg2.jpg"
        class="mobile-fa-jqr-cover-bg-img"
      />

      <div
        class="flexslider flexslider-fa-mobile mobile_jqr"
      >
        <ul class="slides">
          <li>
            <dl>
              <img
                class="mobile-fa-img"
                src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaituozhe/jqr/fa-jqr-1.png"
              />
            </dl>
          </li>
          <li>
            <dl>
              <img
                class="mobile-fa-img"
                src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaituozhe/jqr/fa-jqr-2.png"
              />
            </dl>
          </li>
        </ul>
      </div>
    </div>

    <div class="mobile-jqr-conver-bg-div" v-if="state.showWebSite == false">
      <img
        src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaituozhe/jqr/mobile-fs-jqr-bg2.jpg"
        class="mobile-fa-jqr-cover-bg-img"
      />

      <div
        class="flexslider flexslider-fa-mobile mobile-jqr-fs"
      >
        <ul class="slides">
          <li>
            <dl>
              <img
                class="mobile-fa-img"
                src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaituozhe/jqr/fs-jqr-1.png"
              />
            </dl>
          </li>
          <li>
            <dl>
              <img
                class="mobile-fa-img"
                src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaituozhe/jqr/fs-jqr-2.png"
              />
            </dl>
          </li>
          <li>
            <dl>
              <img
                class="mobile-fa-img"
                src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaituozhe/jqr/fs-jqr-3.png"
              />
            </dl>
          </li>
        </ul>
      </div>
    </div>

    <div class="flexslider flexslider1" v-if="state.showWebSite == false">
      <ul class="slides">
        <li>
          <dl>
            <img
              class="k1-2-img"
              src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaituozhe/jqr/ma-jqr-bg2.jpg"
            />
          </dl>
        </li>
        <li>
          <dl>
            <img
              class="k1-2-img"
              src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaituozhe/jqr/ma-jqr-bg22.jpg"
            />
          </dl>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onBeforeUnmount, onMounted, reactive } from "vue";
export default {
  name: "About",
  components: {},
  setup() {
    const { proxy } = getCurrentInstance();

    const state = reactive({
      showFullVideoFlag: false,
      selJqrType: "",
      jqrMovieUrl: "",
      appType: "Web",
      selFaType: "01",
      selFsType: "01",
      timeTypeLooper: null,
      showWebSite: false,
    });

    const viewKaituozheVideo = (type) => {
      state.selJqrType = type;

      const jqrMovieObj = {
        "03": "https://img.cdn.wxzhida.cn/shzd-web-site/images/jiqiren/1%E5%85%A8%E6%96%B0%E5%AE%B6%E7%94%A8%E8%87%AA%E5%8A%A8%E5%85%85%E7%94%B5%E6%9C%BA%E5%99%A8%E4%BA%BA.mp4",
        "01": "https://img.cdn.wxzhida.cn/shzd-web-site/images/jiqiren/2%E5%85%A8%E6%96%B0%E5%95%86%E7%94%A8%E8%87%AA%E5%8A%A8%E5%85%85%E7%94%B5%E6%9C%BA%E5%99%A8%E4%BA%BA1.mp4",
        "02": "https://img.cdn.wxzhida.cn/shzd-web-site/images/jiqiren/3%E5%95%86%E7%94%A8%E7%A7%BB%E5%8A%A8-%E6%8A%93%E6%9E%AA%E5%BC%8F%E8%87%AA%E5%8A%A8%E5%85%85%E7%94%B5%E6%9C%BA%E5%99%A8%E4%BA%BA2.mp4",
        "04": "https://img.cdn.wxzhida.cn/shzd-web-site/images/jiqiren/4%E5%95%86%E7%94%A8%E7%A7%BB%E5%8A%A8%E8%87%AA%E5%8A%A8%E5%85%85%E7%94%B5%E6%A1%A9.mp4",
      };
      state.jqrMovieUrl = jqrMovieObj[type];
      state.showFullVideoFlag = true;
    };

    const startTypeLooper = () => {
      if (state.selFaType == "01") {
        state.selFaType = "02";
      } else {
        state.selFaType = "01";
      }

      if (state.selFsType == "01") {
        state.selFsType = "02";
      } else if (state.selFsType == "02") {
        state.selFsType = "03";
      } else {
        state.selFsType = "01";
      }

      state.timeTypeLooper = setTimeout(() => {
        startTypeLooper();
      }, 3000);
    };

    onBeforeUnmount(() => {
      clearTimeout(state.timeTypeLooper);
    });

    onMounted(() => {
      document.title = "机器人";

      state.showWebSite = true;
      console.log(window.document.innerWidth)
      if (window.screen.width < 500) {
        state.showWebSite = false;
      }

      state.selFaType = "01";
      state.selFsType = "01";
      state.timeTypeLooper = setTimeout(() => {
        startTypeLooper();
      }, 3000);

      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //安卓端
      var isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //IOS端
      const innerWidth = window.innerWidth;
      state.appType = "Web";
      if (isAndroid && innerWidth < 450) {
        state.appType = "Android";
      }
      if (isIos && innerWidth < 450) {
        state.appType = "iOS";
      }


      setTimeout(() => {
        $(".mobile_jqr").flexslider({
          animation: "slide",
          start: function (slider) {
            $("body").removeClass("loading");
          },
        });
        $(".mobile-jqr-fs").flexslider({
          animation: "slide",
          start: function (slider) {
            $("body").removeClass("loading");
          },
        });

        $(".flexslider1").flexslider({
        animation: "slide",
        slideshowSpeed: 4000,
        start: function (slider) {
          $("body").removeClass("loading");
        },
      });
      }, 100);

      // 顶部
      window.scrollTo(0, 0);

      $(".popupbox_i").click(function () {
        $(this).hide();
        $("#myvideo")[0].pause();
      });

      $(".bf").click(function () {
        $(this).hide();
        $("#myvideo")[0].play();
      });

      $(".banner_cp .width1480 .r ol.f dt").click(function () {
        var liindex = $(".banner_cp .width1480 .r ol.f dt").index(this);
        $(this).addClass("o").siblings().removeClass("o");
        $(".banner_cp .width1480 .l dl")
          .eq(liindex)
          .fadeIn(150)
          .siblings("dl")
          .hide();
      });

      $(".ck-slide").ckSlide({
        autoPlay: true, //true
      });

      $(window).scroll(function () {
        //为页面添加页面滚动监听事件
        var wst = $(window).scrollTop(); //滚动条距离顶端值
        for (var i = 1; i < 20; i++) {
          if ($("#k" + i) && $("#k" + i).offset()) {
            //加循环
            if ($("#k" + i).offset().top <= wst) {
              //判断滚动条位置
              $("#k" + i).addClass("on"); //给当前导航加c类
            } else {
              //$("#p"+i).removeClass('on');
            }
          }
        }
      });
    });
    return {
      state,
      viewKaituozheVideo,
    };
  },
};
</script>

<style lang="less" scoped>
.jqr_video_style {
  width: 100%;
}

.video-bg-dl {
  padding-top: 150px;
}

.video-bg-dl a {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  color: #ffffff;
  display: flex;
  text-align: center;
}

.video-bg-dl ol {
  font-size: 1.5rem;
  position: relative;
  display: flex;
  width: 666px;
  justify-content: center;
  margin-top: 330px;
}

.video-bg-dl ol i {
  transition: all 0.5s ease;
  border: 2px solid #ffffff;
  position: absolute;
  width: 2.875rem;
  height: 3.375rem;
  line-height: 3.375rem;
  border-radius: 50%;
  font-weight: 300;
  padding-left: 0.5rem;
}

.kaituozhe-flex-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.kaituozhe-flex-div a {
  margin: 2.5vh 20px 0px !important;
}

.kaituozhe-flex-div .movie-view-a {
  color: #364166 !important;
  background-color: #ffffff !important;
  border: 2px solid #364166 !important;
  font-weight: 600;
}

.template-full-mask-div {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000000d4;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10086;
}

.center-view-view-div {
  width: 80%;
  height: calc(80% - 88px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.kaituozhe_video_style {
  z-index: 10089;
  width: 100%;
  height: 100%;
}

.fixed-close-icon-div {
  position: fixed;
  right: 32px;
  top: 32px;
  color: #f2f2ff;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 400;
}

.k1-2-img {
  margin-bottom: -10px;
}

.mg-top-4rem {
  margin-top: 4rem;
}

.height-16 {
  height: 16px;
}

.ktz-white-img {
  width: 580px;
  height: 580px;
  margin-top: 20px !important;
}

dt.set-width-dt {
  width: 250px;
}

.relative-div {
  position: relative;
}

.absolute-view-flex-div-01 {
  position: absolute;
  left: 12%;
  bottom: 25%;
}
.absolute-view-flex-div-01-h5 {
  position: absolute;
  left: 12%;
  bottom: 20%;
}

.absolute-view-flex-div-02 {
  position: absolute;
  left: 54.5%;
  bottom: 18%;
}

.absolute-view-flex-div-02-h5 {
  position: absolute;
  left: 54.5%;
  bottom: 18%;
}

.absolute-view-flex-div-03 {
  position: absolute;
  left: 54.5%;
  bottom: 23%;
}
.absolute-view-flex-div-03-h5 {
  position: absolute;
  left: 54.5%;
  bottom: 16%;
}

.absolute-view-flex-div-04 {
  position: absolute;
  left: 18%;
  bottom: 22%;
}
.absolute-view-flex-div-04-h5 {
  position: absolute;
  left: 18%;
  bottom: 15%;
}

.movie-view-a-jqr {
  font-weight: 600;
  display: block;
  color: #ffffff;
  background-color: #364166;
  width: 11.25rem;
  height: 3rem;
  line-height: 3rem;
  border-radius: 2rem;
  text-align: center;
  margin: 2.5vh auto 0px;
  transition: all 0.5s ease;
  font-size: 1rem;
  color: #364166 !important;
  background-color: #ffffff !important;
  border: 2px solid #364166 !important;
}

.movie-view-a-jqr-h5 {
  font-weight: 600;
  display: block;
  color: #ffffff;
  background-color: #364166;
  width: 5.25rem;
  height: 1rem;
  line-height: 1rem;
  border-radius: 2rem;
  text-align: center;
  margin: 2.5vh auto 0px;
  transition: all 0.5s ease;
  font-size: 0.5rem;
  color: #364166 !important;
  background-color: #ffffff !important;
  border: 2px solid #364166 !important;
}

.ck-slidebox {
  position: absolute;
  bottom: 3.25rem;
  left: 50%;
  right: 0px;
  margin-left: 0px !important;
  z-index: 5;
  transform: translateX(-70px);
}

.flexslider {
  position: relative;
}

.widthfull {
  max-width: 100%;
  width: 100%;
}

.jqr-cover-bg-div {
  margin-top: 70px;
  position: relative;

  .absolute-product-img-div {
    position: absolute;
    right: 12vw;
    top: 30%;
    height: 30%;
    width: 24vw;
  }

  .flex-center-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fa-jqr-pro-img {
    max-height: 65vh;
  }

  .flex-radio-sel-div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .radio-sel-child-div {
      border: 1px solid #000;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .radio-sel-child-color-div {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #e6a30b;
      }
      .bg-02 {
        background-color: #1a49bc;
      }
    }
    .margin-right-24 {
      margin-right: 16px;
    }
  }
}

.jqr-cover-bg-fs-div {
  position: relative;
  margin-top: -5px;
  margin-bottom: -5px;

  .fs-absolute-product-img-div {
    position: absolute;
    left: 12%;
    width: 30vw;
    top: 30%;
  }

  .flex-center-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fs-jqr-pro-img {
    max-height: 60vh;
  }

  .flex-radio-sel-div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    .radio-sel-child-div {
      border: 1px solid #000;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .radio-sel-child-color-div {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #e6a30b;
      }
      .bg-02 {
        background-color: #1a49bc;
      }
      .bg-03 {
        background-color: #50b7c8;
      }
    }
    .margin-right-24 {
      margin-right: 16px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.margin-top-50 {
  margin-top: 50px;
}

.mobile-jqr-conver-bg-div {
  position: relative;
}

.flexslider-fa-mobile {
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  height: 220px;
}

.mobile-fa-img {
  max-height: 200px;
}

.slides dl{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
